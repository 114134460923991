<template>
  <h1>R&S</h1>
</template>

<script>
export default {
  name: "RecrutamentoESelecao"

}
</script>

<style>

</style>